<i18n>
ru:
  accrualPoints: '+{amount}'
  loadMore: Загрузить еще
  pointsHistory: История баллов
  pointsTypeAbortOrder: Отмена заказа
  pointsTypeAutomaticRefillWallet: Изменение баланса
  pointsTypeCancelPayFromWallet: Отмена оплаты заказа со счёта
  pointsTypeCancelRefillWalletFromOrder: Отмена пополнения баланса из заказа
  pointsTypeCloseOrder: Закрытие заказа
  pointsTypeCouponActivation: Активация купона
  pointsTypeDiscountSum: Применение скидки
  pointsTypeOther: Неизвестная операция
  pointsTypePayFromWallet: Оплата заказа со счёта
  pointsTypeRefillWallet: Изменение баланса
  pointsTypeRefillWalletFromApi: Пополнение счёта
  pointsTypeRefillWalletFromOrder: Пополнение счёта из заказа
  pointsTypeRemoveGuestCategory: Прочая операция
  pointsTypeResetAutomaticRefillWallet: Сгорание пополнения счёта
  pointsTypeResetRefillWallet: Сгорание пополнения счёта
  pointsTypeSetGuestCategory: Прочая операция
  pointsTypeWelcomeBonus: Приветственный бонус
ua:
  accrualPoints: '+{amount}'
  loadMore: Завантажити більше
  pointsHistory: Історія балів
  pointsTypeAbortOrder: Скасування замовлення
  pointsTypeAutomaticRefillWallet: Зміна балансу
  pointsTypeCancelPayFromWallet: Скасування оплати замовлення з рахунку
  pointsTypeCancelRefillWalletFromOrder: Скасування поповнення балансу з замовлення
  pointsTypeCloseOrder: Закриття замовлення
  pointsTypeCouponActivation: Активація купона
  pointsTypeDiscountSum: Застосування знижки
  pointsTypeOther: Невідома операція
  pointsTypePayFromWallet: Оплата замовлення з рахунку
  pointsTypeRefillWallet: Зміна балансу
  pointsTypeRefillWalletFromApi: Поповнення рахунку
  pointsTypeRefillWalletFromOrder: Поповнення рахунку з замовлення
  pointsTypeRemoveGuestCategory: Інша операція
  pointsTypeResetAutomaticRefillWallet: Згоряння поповнення рахунку
  pointsTypeResetRefillWallet: Згоряння поповнення рахунку
  pointsTypeSetGuestCategory: Інша операція
  pointsTypeWelcomeBonus: Привітальний бонус
us:
  accrualPoints: '+{amount}'
  loadMore: Load more
  pointsHistory: Points history
  pointsTypeAbortOrder: Order cancellation
  pointsTypeAutomaticRefillWallet: Balance change
  pointsTypeCancelPayFromWallet: Cancel order payment from wallet
  pointsTypeCancelRefillWalletFromOrder: Cancel balance refill from order
  pointsTypeCloseOrder: Order closure
  pointsTypeCouponActivation: Coupon activation
  pointsTypeDiscountSum: Discount application
  pointsTypeOther: Unknown operation
  pointsTypePayFromWallet: Order payment from wallet
  pointsTypeRefillWallet: Balance change
  pointsTypeRefillWalletFromApi: Wallet refill
  pointsTypeRefillWalletFromOrder: Wallet refill from order
  pointsTypeRemoveGuestCategory: Other operation
  pointsTypeResetAutomaticRefillWallet: Wallet refill burn-out
  pointsTypeResetRefillWallet: Wallet refill burn-out
  pointsTypeSetGuestCategory: Other operation
  pointsTypeWelcomeBonus: Welcome bonus
</i18n>

<template>
  <div class="v-account-points-history">
    <div
      class="v-account-points-history__title v-font-weight-600 v-mb-sm"
      :class="{ 'v-popup-head v-title': fromPopup }"
      v-html="translate('pointsHistory.pointsHistory')"
    />
    <div class="v-account-points-history__container v-scrollbar v-pm-shadow">
      <div
        v-for="(transaction, index) in pointsTransactionsFlatList"
        :key="`points-transaction-${uid}-${index}`"
        class="v-account-points-history__points-transaction v-d-flex v-mb-sm"
      >
        <div class="v-account-points-history__marker-container v-mr-xs">
          <div
            class="v-account-points-history__marker"
            :class="{
              'v-account-points-history__marker--default': transaction.IsInsignificantAccrual,
              'v-account-points-history__marker--success':
                !transaction.IsInsignificantAccrual && transaction.PointsAccrual !== 0,
              'v-account-points-history__marker--error':
                !transaction.IsInsignificantAccrual && transaction.PointsWriteOff !== 0
            }"
          />
        </div>
        <div class="v-account-points-history__info v-d-flex v-flex-column">
          <div
            class="v-account-points-history__info-title"
            v-html="getTransactionTitle(transaction)"
          />
          <div
            class="v-account-points-history__info-date"
            v-html="getTransactionDate(transaction.Created)"
          />
        </div>
        <div
          class="v-account-points-history__points-change v-font-weight-500 v-ml-xs v-d-flex"
          :class="{
            'v-body-text-color-light': transaction.IsInsignificantAccrual,
            'v-success-color': !transaction.IsInsignificantAccrual && transaction.PointsAccrual !== 0,
            'v-error-color': !transaction.IsInsignificantAccrual && transaction.PointsWriteOff !== 0
          }"
        >
          <i18n-t
            v-if="transaction.PointsAccrual !== 0"
            keypath="pointsHistory.accrualPoints"
            scope="global"
          >
            <template #amount>
              <common-currency
                show-points
                :amount="transaction.PointsAccrual"
              />
            </template>
          </i18n-t>
          <common-currency
            v-else
            show-points
            :amount="transaction.PointsWriteOff"
          />
        </div>
      </div>
      <div
        v-if="isVisible"
        class="v-account-points-history__load-more v-text-center v-mb-sm"
      >
        <common-skeleton v-if="!accountStore.PointsHistoryInfo.data?.HistoryLoaded" />
        <arora-button
          v-else
          :label="translate('pointsHistory.loadMore')"
          @click="loadMorePointsHistory"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PointsTransactionType } from '~api/consts'

import type { PointsTransaction } from '~types/accountStore'

const accountStore = useAccountStore()

const { translate } = useI18nSanitized()
const { fromPopup } = useInstance()
const { fromMillisInZone } = useDateTime()

const appConfig = useAppConfig()
const uid = useId()

const currentPage = ref<number>(0)
//TODO: When API will be fixed, and take and skip will work fine
const isVisible = ref<boolean>(false)
const pointsPerPage = 5

const pointsTransactionsFlatList = computed<PointsTransaction[]>(() => {
  return Object.values(accountStore.PointsHistoryInfo.data?.PointsTransactions ?? [])
    .flat()
    .filter(
      (order) =>
        appConfig.RestaurantSettingsPreRun.ShowZeroTransactions ||
        order.PointsAccrual !== 0 ||
        order.PointsWriteOff !== 0
    )
})

const loadMorePointsHistory = async (): Promise<void> => {
  if (
    (accountStore.PointsHistoryInfo.data?.PointsTransactions[currentPage.value] ?? []).length >=
    pointsPerPage
  ) {
    await accountStore.loadPointsHistory(currentPage.value + 1, pointsPerPage)
    currentPage.value++
  } else {
    isVisible.value = false
  }
}

const getTransactionTitle = (transaction: PointsTransaction): string => {
  switch (transaction.Type) {
    case PointsTransactionType.RemoveGuestCategory:
      return translate('pointsHistory.pointsTypeRemoveGuestCategory')
    case PointsTransactionType.SetGuestCategory:
      return translate('pointsHistory.pointsTypeSetGuestCategory')
    case PointsTransactionType.CloseOrder:
      return translate('pointsHistory.pointsTypeCloseOrder')
    case PointsTransactionType.RefillWalletFromApi:
      return translate('pointsHistory.pointsTypeRefillWalletFromApi')
    case PointsTransactionType.AutomaticRefillWallet:
      return translate('pointsHistory.pointsTypeAutomaticRefillWallet')
    case PointsTransactionType.RefillWallet:
      return translate('pointsHistory.pointsTypeRefillWallet')
    case PointsTransactionType.ResetAutomaticRefillWallet:
      return translate('pointsHistory.pointsTypeResetAutomaticRefillWallet')
    case PointsTransactionType.ResetRefillWallet:
      return translate('pointsHistory.pointsTypeResetRefillWallet')
    case PointsTransactionType.PayFromWallet:
      return translate('pointsHistory.pointsTypePayFromWallet')
    case PointsTransactionType.RefillWalletFromOrder:
      return translate('pointsHistory.pointsTypeRefillWalletFromOrder')
    case PointsTransactionType.CancelPayFromWallet:
      return translate('pointsHistory.pointsTypeCancelPayFromWallet')
    case PointsTransactionType.CancelRefillWalletFromOrder:
      return translate('pointsHistory.pointsTypeCancelRefillWalletFromOrder')
    case PointsTransactionType.AbortOrder:
      return translate('pointsHistory.pointsTypeAbortOrder')
    case PointsTransactionType.DiscountSum:
      return translate('pointsHistory.pointsTypeDiscountSum')
    case PointsTransactionType.CouponActivation:
      return translate('pointsHistory.pointsTypeCouponActivation')
    case PointsTransactionType.WelcomeBonus:
      return translate('pointsHistory.pointsTypeWelcomeBonus')
    default:
      return translate('pointsHistory.pointsTypeOther')
  }
}

const getTransactionDate = (date: number): string => {
  return fromMillisInZone(date * 1000, appConfig.RestaurantSettingsPreRun.GMT).toLocaleString({
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  })
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-account-points-history {
  &__title {
    font-size: variables.$TextSizeH3;
  }

  &__container {
    max-height: 350px;
    overflow-y: auto;
  }

  &__marker {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: 3px;

    &--default {
      background: variables.$BodyTextColorLight;
    }

    &--success {
      background: variables.$SuccessColor;
    }

    &--error {
      background: variables.$ErrorColor;
    }
  }

  &__info {
    flex-grow: 1;

    &-title {
      font-size: 1rem;
      margin-bottom: 2px;
    }

    &-date {
      font-size: 0.8rem;
      color: variables.$BodyTextColorLight;
    }
  }

  &__points-change {
    font-size: 1rem;
  }
}
</style>
